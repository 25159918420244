.article-body {
  figure {
    margin: $base-spacing*2 0;
    padding: 0;
    display: flex;
    align-items: flex-start;
    flex-flow: row wrap;
    justify-content: center;

    &.center { text-align: center; }
    &.left { text-align: left; }
    &.right { text-align: right; }

    figcaption {
      font-size: modular-scale(-1);
      padding: 0 1em;

      small {
        font-size: modular-scale(-3);
      }
    }
  }

  p > img,
  video,
  .fig {
    width: 100%;
    padding: 0;
    margin: 0;
    text-align: center;
  }

  .fig-medium {
    @include media($large-viewport) {
      padding: 0 20%;
    }
  }

  .fig-small {
    @include media($large-viewport) {
      padding: 0.5em 0 0 2em;
      width: 40%;
      float: right;
    }
  }
}