article header,
article footer {
  .share {
    vertical-align: bottom;
    margin-top: $base-spacing;
    margin-bottom: $base-spacing;

    .twitter-share-button {
      vertical-align: bottom;
    }

    .fb_iframe_widget_fluid {
      vertical-align: bottom;
    }
  }
}

article header {
  margin-bottom: $base-spacing;
  
  .meta.subtitle {
    display: block;
    margin-top: calc($base-spacing/2);
    font-size: modular-scale(2);
  }

  .meta.time {
    font-size: modular-scale(-1);
    margin-top: calc($base-spacing/2);
  }

  .meta.tags {
    display: inline;
    li {
      display: inline;
    }
  }
}
