.article.meta {
  $color: $base-font-color;
  margin: $base-spacing 0;

  .link {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: center;
    color: $color;
    text-decoration: none;
    
    .thumbnail {
      flex: 1 0 150px;
      width: 100%;
      margin: 0;
      padding: 20px;
      object-fit: contain;
      object-position: 50% 50%;
      
      @include media($large-viewport) {
        flex: 1 0 150px;
        margin: 0;
        padding: 20px;
        min-width: 100px;
        min-height: 100px;
        max-width: 200px;
        max-height: 200px;
        object-fit: contain;
        object-position: 0% 0%;
      }
    }

    .text {
      flex: 1 2 350px;
      padding: 10px 0;

      .title {
        font-size: modular-scale(2);
        font-weight: bold;
        line-height: $header-line-height;
        margin: 0 0 calc($base-spacing/3) 0;

        .meta.title {
          margin: 0;
          font-size: modular-scale(3);
        }
        
        .meta.subtitle {
          display: block;
          margin-top: calc($base-spacing/2);
          font-size: modular-scale(0);
        }
      }
      
      .meta.description {
        margin-bottom: $base-padding;
      }

      .meta.time {
        font-size: modular-scale(-1);
      }

      .meta.tags {
        font-size: modular-scale(-1);
      }
    }
  }
}
