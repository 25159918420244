.article-body {
  @include clearfix;
  
  &:last-child:after {
    display: block;
    content: "";
    clear: both;
  }
  
  a {
    text-decoration: underline;
    text-decoration-color: $base-link-underline-color;
  }
  
  ul {
    @extend %default-ul;
  }
  
  ol {
    @extend %default-ol;
  }
  
  dl {
    dt {
      margin: calc($base-spacing/2) 0;
    }
    
    dd {
      margin: calc($base-spacing/2) 0 $base-spacing 0;
    }
  }
  
  pre {
    overflow-x: scroll;
  }
  
  .page-description {
    margin: $base-spacing 0;
    padding: calc($base-spacing/2);
    border: $base-border;
  }
  
  .big-quote {
    border: none;
    margin: 0;
    padding: calc($base-spacing/2);
    font-size: modular-scale(2);
    font-style: italic;
  }
}
