body {
  margin: 0;
  word-wrap: break-word;
}

.container {
  @include outer-container;
  padding: 0 $base-spacing;
}

.main.container {
  padding-top: $base-spacing;
}

main {
  h1 {
    margin: 0 0 calc($base-spacing/2) 0;
  }
  h2, h3, h4, h5, h6 {
    margin: $base-spacing 0 $base-spacing 0;
  }
}

small,
.small {
  font-size: modular-scale(-1);
}
