.contacts {
  list-style-type: none;
  padding-left: 0 !important;
  margin: $base-spacing*2 0;

  li {
    display: inline-block;
    text-align: center;
    line-height: 1.0;
    font-size: modular-scale(-3);
    img {
      width: 5em;
      margin: 2px;
    }
    a {
      text-decoration: none;
    }
  }
}
