.jekyll-linkpreview-wrapper {
  margin: $base-spacing 0;
}

.jekyll-linkpreview-wrapper p a[href] {
  display: none;
}

.jekyll-linkpreview-wrapper-inner {
  border: 1px solid rgba(0,0,0,.1);
  padding: $base-padding;
  max-width: 25rem;
}

.jekyll-linkpreview-content {
  position: relative;
  overflow: hidden;
}

.jekyll-linkpreview-image {
  padding: 1em;
  img, picture {
    width: 100%;
  }
}

.jekyll-linkpreview-title {
  margin: 0;
  font-size: modular-scale(0);
}

.jekyll-linkpreview-description {
  font-size: modular-scale(-1);
}

.jekyll-linkpreview-footer {
  font-size: modular-scale(-2);
}
