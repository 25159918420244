@import "neat/neat-helpers"; // or "../neat/neat-helpers" when not in Rails

// Neat Overrides
$column: 90px;
$gutter: 30px;
$grid-columns: 6;
$max-width: 760px;
// $visual-grid: true;

// Neat Breakpoints
$first-breakpoint-value: 480px;
$large-viewport: new-breakpoint(min-width $first-breakpoint-value + 1 12);
