@charset "UTF-8";

// Typography
$base-font-family: Verdana, "游ゴシック", YuGothic, "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
$header-font-family: $base-font-family;

// Unit Sizes
$em-base: 18px;

$bourbon: (
    "modular-scale-base": $em-base,
    "modular-scale-ratio": $major-second,
);

// Font Sizes
$base-font-size: $em-base;

// Line height
$base-line-height: 2.0;
$header-line-height: 1.5;

// Other Sizes
$base-border-radius: 0.5em;
$base-spacing: $base-line-height * 1em;
$base-padding: 1em;

// Colors
$base-accent-color: #CC4400;
$base-main-color: #208DC3;
$base-background-color: lighten($base-main-color, 55);
$base-font-color: darken($base-main-color, 25);
$base-inverted-color: lighten($base-main-color, 55);
$base-inverted-background-color: darken($base-main-color, 35);

// Link Colors
$base-link-color: lighten($base-font-color, 20);
$base-link-underline-color: lighten($base-link-color, 20);
$base-hover-link-color: darken($base-link-color, 20);
$base-button-color: $base-inverted-background-color;
$base-button-text-color: $base-inverted-color;
$base-hover-button-color: $base-hover-link-color;

// Border color
$base-border-color: $base-font-color;
$base-border: 1px solid $base-border-color;
